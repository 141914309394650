import { useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useTheme } from 'styled-components'
import { postV2AuthTokenChatbot } from '@nordic-web/rest-codegen/generated/auth2'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { useDidUpdateEffect } from '@nordic-web/utils/hooks/use-did-update-effect'
import { brandConfig } from '@/config/brand'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { paths } from '@/helpers/paths'
import { useGlobalEventListener } from '@/hooks/use-global-events'

const pathsWithChat = [
  paths.faq.urlString(),
  paths.packages.urlString(),
  paths.settings.urlString(),
  brandConfig.translatedPaths.checkout,
]

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    kindlyChat: LegitimateAny
    kindlyOptions: LegitimateAny
  }
}
const script = `https://chat.kindlycdn.com/kindly-chat.js`

export const KindlyChat = () => {
  const router = useRouter()
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false)
  const [isKindlyLoaded, setIsKindlyLoaded] = useState(false)
  const theme = useTheme()
  const { user } = useUserQuery()

  const { isLoggedIn } = useAuthenticationStore()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  async function updateContext() {
    const accessToken = await authenticationStore.getValidAccessToken()
    window.kindlyChat.setNewContext({ accessToken })
  }

  useEffect(() => {
    const userDetails = user?.details
    if (!userDetails || !isKindlyLoaded) return

    const user_name = `${userDetails.firstName} ${userDetails.lastName}`
    window.kindlyChat?.identifyUser?.({
      full_name: user_name,
      email: userDetails.email,
    })

    window.kindlyChat?.setNewContext?.({
      name: user_name,
    })
  }, [user, isKindlyLoaded])

  useDidUpdateEffect(() => {
    if (!isLoggedIn) return
    updateContext()

    async function triggerSilentUpdate() {
      const current_context = await window.kindlyChat.getContext()

      if (current_context?.salesforce_case_id) {
        window?.kindlyChat?.triggerDialogue('silent_update')
      }
    }

    triggerSilentUpdate()
  }, isLoggedIn)

  useEffect(() => {
    const boot = async () => {
      const token = await authenticationStore.getValidAccessToken()
      window.kindlyOptions = {
        getAuthToken: async (chatId: string) => {
          try {
            const token = await authenticationStore.getValidAccessToken()
            if (!token) return

            const response = await postV2AuthTokenChatbot({
              headers: {
                authorization: formatAuthorizationHeader(token),
              },
              body: {
                grant_type: 'access_token',
                access_token: token,
                chat_id: chatId,
              },
              throwOnError: true,
            })

            return response.data.token
          } catch (error) {
            Bugsnag.notify('Error getting chat token', (event) => {
              event.addMetadata('details', { error })
            })
          }
        },
        bubbleHidden: true,
        customFont: theme.fontFamily,
        context: {
          accessToken: token,
        },
      }
      // Without this the options aren't populated before we load the script, which mean the auth call does not happen on the first opening of the chat
      setTimeout(() => setIsOptionsLoaded(true), 1)
    }

    boot()
  }, [theme])

  const shouldShowChat = pathsWithChat.some((path) => router.asPath.startsWith(path))

  useEffect(() => {
    if (!window?.kindlyChat || !isKindlyLoaded) return

    if (shouldShowChat) {
      window.kindlyChat.showBubble()
    } else {
      window.kindlyChat.hideBubble()
      window.kindlyChat.closeChat()
    }
  }, [shouldShowChat, isKindlyLoaded])

  async function closeCase() {
    const context = await window.kindlyChat.getContext()
    const case_id = context.salesforce_case_id || ''

    if (case_id.length > 0) {
      fetch('https://europe-west1-cust-mtv.cloudfunctions.net/closeCaseOnEnd', {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          case_id: case_id,
        }),
      })
    }
  }

  useGlobalEventListener('kindly:load', () => {
    setIsKindlyLoaded(true)
  })

  useGlobalEventListener('kindly:ui', async (type: LegitimateAny) => {
    if (type?.detail?.type === 'end-chat') closeCase()
  })

  useGlobalEventListener('kindly:message', async () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      closeCase()
    }, 900000) // 15 minutes
  })

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (!isOptionsLoaded) return null

  return <Script id="kindly-chat" src={script} data-bot-key={brandConfig.kindlyDataBotKey} data-shadow-dom defer />
}
